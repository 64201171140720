export const visitorExperiments = {
  Monthly_Billing: ['on', 'off'],
  PUG_Plans_Page_Staircase: ['v1', 'off'],
  OG_Homepage_Plan_Visible: ['v1', 'v2'], // Contentful Split experiment
} as const;

export const userExperiments = {
  Are_Red_Buttons_Better: ['on', 'off'],
  Tosse_Test_Users: ['on', 'off'],
  Test_That_Doesnt_Exist_Users: ['on', 'off'],
  PAY_License_Selector: ['on', 'off'],
  PAY_Quote_Button: ['on', 'off'],
} as const;

export type ContentVisitorExperiments = typeof visitorExperiments;
export type ContentUserExperiments = typeof userExperiments;
