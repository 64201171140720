import { useFeatures } from '@mentimeter/workspace-features';
import { useUserSplitsLazy } from 'src/split-hooks';

export function useLicenseSelectorSplit() {
  const { isFreeTeam, isFreeUser } = useFeatures();
  const lazyTreatment = useUserSplitsLazy();
  const isInLicenseSelectorTreatment =
    lazyTreatment.isReady &&
    lazyTreatment.getSplits(['PAY_License_Selector'], {
      splitAttributes: { isFreeUser, isFreeTeam },
    }).PAY_License_Selector === 'on';

  return {
    isInLicenseSelectorTreatment,
    licenseSelectorExperimentIsReady: lazyTreatment.isReady,
  };
}
