import { useFeatures } from '@mentimeter/workspace-features';
import { useUserSplitsLazy } from 'src/split-hooks';

export function useQuoteButtonSplit() {
  const { isFreeTeam, isFreeUser } = useFeatures();
  const lazyTreatment = useUserSplitsLazy();
  const isInQuoteButtonTreatment =
    lazyTreatment.isReady &&
    lazyTreatment.getSplits(['PAY_Quote_Button'], {
      splitAttributes: { isFreeUser, isFreeTeam },
    }).PAY_Quote_Button === 'on';

  return {
    isInQuoteButtonTreatment,
    quoteButtonExperimentIsReady: lazyTreatment.isReady,
  };
}
