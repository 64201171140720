import { useSubscriptions, billingRules } from '@mentimeter/billing';
import {
  getIsMemberLite,
  getIsMemberLiteInProWorkspace,
  useUser,
} from '@mentimeter/user';
import {
  UserFeaturesTypeEnum,
  PlanKeyEnum,
  PlanFeaturesEnum,
  WorkspaceRoleEnum,
} from '@mentimeter/http-clients';
import { useWorkspace } from '@mentimeter/workspace-data-hooks';
import { userTypes } from './user-access-levels';

export const useFeatures = () => {
  const { user } = useUser();
  const { subscriptions, isLoading: isLoadingSubscriptions } =
    useSubscriptions();
  const { planKey } = billingRules(subscriptions);

  const { data: workspace, isLoading: isLoadingWorkspace } = useWorkspace();

  const isLoadingFeatures =
    !user || isLoadingWorkspace || isLoadingSubscriptions;

  const { isBasicUser, isFreeUser, isProUser, isProMulti } = userTypes(
    user,
    workspace,
  );

  const planFeatures = user?.features.type;
  const licenses = workspace?.activeSubscription?.licenses ?? 0;
  const isEnterpriseTrial = planKey === PlanKeyEnum.ENTERPRISE_TRIAL;
  const isEnterprise = planKey === PlanKeyEnum.ENTERPRISE;

  const isProWorkspace = planFeatures === PlanFeaturesEnum.PRO;

  const featureAccessLevel = isEnterpriseTrial
    ? UserFeaturesTypeEnum.ENTERPRISE_TRIAL
    : user?.features?.type || 'registered';

  // Util
  const isOwnerOrAdmin = user?.ownerOrAdmin;
  const isOwner = user?.role === WorkspaceRoleEnum.OWNER;
  const hasMultipleMembers = workspace && workspace.memberStats.all > 1;
  const hasMultiplePaidLicenses = Boolean(
    workspace && (workspace.activeSubscription?.licenses ?? 0) > 1,
  );
  const isFreeTeam = Boolean(isFreeUser && hasMultipleMembers);
  const isTeamWorkspace = hasMultiplePaidLicenses || hasMultipleMembers;

  // Mixed
  const hasTeamAdminFeatures = isOwnerOrAdmin && isTeamWorkspace;
  const hasMembersTableActions = isOwnerOrAdmin || isFreeTeam;
  const hasWorkspaceInsightsFeature = Boolean(
    isOwnerOrAdmin && (isEnterprise || isEnterpriseTrial),
  );

  const hasCollaborationAccessEdit =
    user?.features.collaboration?.entitlement === 'edit';

  const hasAddMentiToSharedFolder = hasCollaborationAccessEdit;

  // Features based on workspace/subscription
  const isWorkspaceFull = workspace && workspace.availableLicenses < 1;

  const hasVerifiedDomainsFeature = isEnterprise;
  const hasUserCapturingFeatures = user?.features.user_capturing;
  const hasScimFeature = hasUserCapturingFeatures;
  const hasEmailDomains = !(
    isEnterprise ||
    workspace?.samlLoginUrl ||
    isEnterpriseTrial
  );
  const hasSsoFeature =
    (licenses && licenses >= 10 && planKey === PlanKeyEnum.INVOICE) ||
    isEnterprise; // extract to BE
  const hasSamlConfig = Boolean(workspace?.samlLoginUrl);
  const hasDowngrade = user?.features.downgrade;
  const hasInvitationLinkToggle = !workspace?.domainRestriction;
  const hasNonMembers = hasUserCapturingFeatures;
  const hasEnterpriseFilterMembersOptions =
    workspace?.samlLoginUrl || isEnterprise;

  // Features based on user
  const hasWorkspacePresentationsPage =
    isFreeUser || workspace?.sharedSeriesAccess === 'anyone';
  const hideWorkspacePresentationPage =
    workspace?.sharedSeriesAccess === 'no_one';

  const isMember = !isOwnerOrAdmin && (!user?.isFreeUser || isFreeTeam);
  const hasSharedTemplatesFeature =
    featureAccessLevel === UserFeaturesTypeEnum.PRO ||
    featureAccessLevel === UserFeaturesTypeEnum.ENTERPRISE ||
    featureAccessLevel === UserFeaturesTypeEnum.ENTERPRISE_TRIAL;
  const hasInviteMembersFeature = isOwnerOrAdmin || isFreeUser;
  const hasFindAvailableTeamsFeature = !isTeamWorkspace;
  const hasWorkspaceSettingsFeature = !isMember;

  const hasGroupsFeature = Boolean(user?.features.groups);
  const canManageGroups = Boolean(
    user?.features.groups && user?.features.manage_groups,
  );

  const hasEnterpriseManageMembersFeatures =
    Boolean(workspace?.samlLoginUrl) || isEnterprise || isEnterpriseTrial;
  const hasInviteExportButton =
    isOwnerOrAdmin || isFreeUser || isBasicUser || isProUser;
  const isRestrictionsFreeMember = isMember && !workspace?.hideUsersFromMembers;
  const allTeamMembers = Boolean(
    hasTeamAdminFeatures || isRestrictionsFreeMember,
  );

  const showMembersTableSummary = allTeamMembers;
  const hasSearchManageMembers = allTeamMembers;
  const hasFilterManageMembers = allTeamMembers;
  const hasAdminRole = !isFreeTeam;

  const isMemberLite = getIsMemberLite(user);
  const isProMemberLite = getIsMemberLiteInProWorkspace(user);

  const hasExcelExportPaywall = isFreeUser || isBasicUser || isProMemberLite;
  const hasAssignAdminPaywall = isFreeUser;
  const hasLicenseSelfService = isProMulti || isProUser || isBasicUser;

  const hasFilterPresentationsBySharing =
    (isProWorkspace || isEnterprise || isEnterpriseTrial) && !isMemberLite;

  const requestPageEnabledByWorkspaceSettings =
    workspace?.requestToJoinEnabled === true ||
    workspace?.requestRoleUpgradeEnabled === true;

  const hasWorkspaceRequestsPage =
    requestPageEnabledByWorkspaceSettings && !isFreeUser && isOwnerOrAdmin;

  return {
    isLoadingFeatures,
    featureAccessLevel,
    hasWorkspaceInsightsFeature,
    hasDowngrade,
    hasEnterpriseFilterMembersOptions,
    hasEmailDomains,
    hasEnterpriseManageMembersFeatures,
    hasExcelExportPaywall,
    hasSearchManageMembers,
    hasFilterManageMembers,
    hasFindAvailableTeamsFeature,
    hasGroupsFeature,
    canManageGroups,
    hasInvitationLinkToggle,
    hasInviteExportButton,
    hasInviteMembersFeature,
    hasLicenseSelfService,
    hasSamlConfig,
    hasScimFeature,
    hasSharedTemplatesFeature,
    hasSsoFeature,
    hasTeamAdminFeatures,
    hasWorkspaceSettingsFeature,
    hasNonMembers,
    hasVerifiedDomainsFeature,
    hasWorkspacePresentationsPage,
    hasWorkspaceRequestsPage,
    hideWorkspacePresentationPage,
    isOwnerOrAdmin,
    isOwner,
    isWorkspaceFull,
    isRestrictionsFreeMember,
    showMembersTableSummary,
    hasUserCapturingFeatures,
    hasMembersTableActions,
    hasCollaborationAccessEdit,
    hasAssignAdminPaywall,
    hasFilterPresentationsBySharing,
    hasAdminRole,
    isFreeUser,
    isFreeTeam,
    hasAddMentiToSharedFolder,
  };
};
