import * as React from 'react';
import { Box } from '@mentimeter/ragnar-ui';
import { useTranslation } from 'react-i18next';
import { Link } from '../../actions';

export const EducationalProfessionalPillToggle = ({
  isEducation,
  onClickEducation,
}: {
  isEducation: boolean;
  onClickEducation: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Box
      flexDirection="row"
      bg="neutralWeak"
      borderRadius="48px"
      p="space1"
      m="0 auto"
      alignItems="stretch"
    >
      <TabLink href="/plans" highlighted={!isEducation}>
        {t('plansContentPackage:plans.professional_link')}
      </TabLink>

      <TabLink
        onClick={onClickEducation}
        href="/plans/education"
        highlighted={isEducation}
      >
        {t('plansContentPackage:plans.educational_link')}
      </TabLink>
    </Box>
  );
};

interface TabLinkProps {
  children: React.ReactNode;
  onClick?: () => void;
  href?: string;
  highlighted: boolean;
}

export const TabLink = ({
  children,
  onClick,
  href,
  highlighted,
}: TabLinkProps) => {
  return (
    <Link
      onClick={onClick}
      href={href}
      px="space8"
      py="space3"
      bg={highlighted ? 'bg' : 'neutralWeak'}
      underline={false}
      lineHeight="tight"
      textAlign="center"
      extend={() => ({
        placeContent: 'center',
        opacity: highlighted ? 1 : 0.55,
        borderRadius: '48px',
        '&:hover': { opacity: 1 },
      })}
    >
      {children}
    </Link>
  );
};
